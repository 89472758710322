export default function parseStats(data, frequency){
    let stats = {news:null,
      twitter:null,
      reddit:null,
      other:null,
      total:null};
    if(Object.keys(data).length !== 0){
      let changes = data.changes;
      if(frequency === 'weekly'){
        stats = {news:changes.news_7day_change,
                    twitter:changes.twitter_7day_change,
                    reddit:changes.reddit_7day_change,
                    other:changes.other_7day_change,
                    total:changes.total_7day_change}
      }
      else if(frequency === 'monthly'){
        stats= {news:changes.news_30day_change,
                    twitter:changes.twitter_30day_change,
                    reddit:changes.reddit_30day_change,
                    other:changes.other_30day_change,
                    total:changes.total_30day_change}
      }
      else {
        stats = {news:changes.news_1day_change,
                twitter:changes.twitter_1day_change,
                reddit:changes.reddit_1day_change,
                other:changes.other_1day_change,
                total:changes.total_1day_change}
      }
    }
    return stats
};