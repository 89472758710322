import React from 'react';

import './Stats.css'

class Stats extends React.Component{
    render(){
        let value = this.props.value;
        let direction = '';
        let text = '';
        if (value === null){
            direction = '';
            text = '-';
        }
        else if((typeof value) === 'string'){
            text = value;
            direction = '';
        }
        else{
            text = value + '%';
            if(value > 0 ){
                direction = <img src='up-arrow.png' alt='up-arrow' className='direction'/>
            }
            else if (value <0){
                direction =  <img src='down-arrow.png'  alt='down-arrow' className='direction'/>
            }
            else {
                direction = ''
            }
        }
        return (
            <div className='statsBox'>
                <div className='statsTitle'>
                    <div className='vertical-center' style={{'marginLeft':'20px'}}>
                        <b>{this.props.title}</b>
                    </div>
                </div>
                <div className='statsData'>
                    <div className='vertical-center'>
                        <div style={{'display':'flex', 'width':'100%'}}>
                            <div className='left'>
                                {text}
                            </div>
                            <div className='right'>
                                {direction}
                            </div>
                        </div>
                    </div>
                    

                </div>
            </div>
        )
    }
}


class StatsTable extends React.Component {

    render(){
        return (
            <div className='statsContainer'>
                <Stats title='Symbol' value={this.props.symbol}/>
                <div style={{'borderLeft':'1px solid black'}}/>
                <Stats title='Name' value={this.props.companyName}/>
                <div style={{'borderLeft':'1px solid black'}}/>
                <Stats title='News Mentions' value={this.props.data.news}/>
                <div style={{'borderLeft':'1px solid black'}}/>
                <Stats title='Twitter Mentions' value={this.props.data.twitter}/>
                <div style={{'borderLeft':'1px solid black'}}/>
                <Stats title='Reddit Mentions' value={this.props.data.reddit}/>
                <div style={{'borderLeft':'1px solid black'}}/>
                <Stats title='Other Mentions' value={this.props.data.other}/>
                <div style={{'borderLeft':'1px solid black'}}/>
                <Stats title='Total Mentions' value={this.props.data.total}/>
            </div>
        )
    }

}

export default StatsTable;