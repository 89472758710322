import React from "react";
import Chart from 'react-apexcharts'
import Form from "react-bootstrap/Form";

import './Chart.css'

const Markers = (props) => {
      const opts = props.options.map(x => (
          <div className='marker' value={x[0]} id={x[0]} key={x[0]} style={{'display':'flex'}} onClick={props.onClick}>
            <div className='outer-circle' value={x[0]} style={{'background':x[1]}}>
              <div className={`inner-circle ${props.markerStatuses[x[0]]}`} value={x[0]}></div>
            </div>
            <p value={x[0]} style={{'margin':'0px 0px 0px 10px'}}> {x[0]} Mentions</p>
          </div>
          
      ))
      return <div className={`legendMarkers`}>{opts}</div>
  };


class ApexChart extends React.Component {
      render() {
        let markerDetails = [['News','#452AE7'], ['Twitter','#00ACEE'], ['Reddit','#FF4500'], ['Other','#00FF85'], ['Total','#C4C4C4']]
        let calculated = ''
        if(this.props.chartLength === "12months"){
          calculated = 'Calculated weekly'
        }
        else {
          calculated = 'Calculated daily'
        }
        return (
          <div className="containerChart">
            <div className='chartHeader'>
                <div className='vertical-center'>
                  <div className='chartTitle'>
                      <div className='left'>
                        <b className='vertical-center'>{this.props.symbol !== '-' ? this.props.symbol + ' - ' + this.props.companyName : 'Search for a company'}</b>
                      </div>
                      <div className='right' style={{'display':'flex'}}>
                          
                          <p className='lengthDescription vertical-center'>{calculated}</p>
                          <Form.Select aria-label="chart-length" size="sm" className="chartLengthSelect " style={{'position':'relative'}} onChange={this.props.onChange}>
                              <option value="3months">3 Month View</option>
                              <option value="6months">6 Month View</option>
                              <option value="12months">12 Month View</option>
                          </Form.Select>
                        </div>
                
                      </div>
                </div>
            </div>
            <div className="box">
              <div className="legend">
                Social Chatter <br/>
                <Markers options={markerDetails} markerStatuses={this.props.markerStatuses}onClick={this.props.onClick}/>
              </div>
              <div className='plotArea'>

              <div className="chart">
                <Chart
                  options={this.props.chartOptions}
                  series={this.props.chartSeries}
                  type="bar"
                  height="100%"
                  position='relative'
                />
              </div>
              </div>
            </div>
          </div>
        );
      }
    };

export default ApexChart;