import React from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import InputGroup from "react-bootstrap/InputGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

import jsonData from './symbols.json';
import "./Search.css"

let symbols_ = JSON.parse(JSON.stringify(jsonData));

const Suggestions = (props) => {
    if(props.options.length === symbols_.length){
        return 
    }
    else{
        const opts = props.options.map(symb => (
            <li key={symb.symbol+ symb.exchange} value={symb.symbol} onClick={props.clickSuggestions}>
                <b>{symb.symbol}</b> ({symb.exchange})
                <br/>{symb.company}
            </li>
        ))
        return <ul className={`suggestions ${props.visible}`}>{opts.slice(0,5)}</ul>
    }
};
  

class Search extends React.Component {
    

    render () {
      return (
          <div style={{'textAlign':'center'}}>
            <div className="searchContainer">
                <div>
                    <InputGroup className="mb-3 search" size="sm">
                    <FormControl
                        placeholder="Symbol"
                        aria-label="Symbol"
                        aria-describedby="basic-addon1"
                        className="text-center shadow-none searchBar"
                        value={this.props.value}
                        onChange={this.props.handleChange}
                        onBlur={this.props.hideSuggestions}
                        onClick={this.props.showSuggestions}
                    />
                    <Button variant="outline-secondary bg-white"
                            id="button-addon1"
                            className="shadow-none searchButton"
                            onClick={this.props.handleClick}>
                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                    </Button>
                </InputGroup>
                <Suggestions options={this.props.options} visible={this.props.visible} onClick={this.props.clickSuggestions}/>
                </div>
                
            
                <Form.Select aria-label="asset-type" size="sm" className="assetSelect" onChange={this.props.handleAssetType}>
                    <option value="equities">Equity</option>
                    <option value="crypto">Crypto</option>
                </Form.Select>

                <Form.Select aria-label="frequency-type" size="sm" className="frequencySelect" onChange={this.props.handleFrequency}>
                    <option value="daily">24 Hours</option>
                    <option value="weekly">Weekly</option>
                    <option value="monthly">Monthly</option>
                </Form.Select>
            </div>
          </div>
      )};
}
export default Search;