var getDaysArray = function(start, end) {
    for(var arr=[],dt=new Date(start); dt<=new Date(end); dt.setDate(dt.getDate()+1)){
        arr.push(new Date(dt));
    }
    return arr;
  };

let COLOURS = {'news':'#452AE7', 'twitter':'#00ACEE', 'reddit':'#FF4500', 'other':'#00FF85','total':'#C4C4C4'};

export default function parseData(data, markerStatuses){
    let widths = [];
        let yaxis_ = [];
        let series_ = [];
        let xdata = [];
        let colours = [];
        if(Object.keys(data).length === 0){
            xdata = getDaysArray(new Date('2022-03-01'),new Date());
            xdata = xdata.map((v)=>v.toISOString().slice(0,10));
            return {
                chart: {
                    options: {
                        chart: {
                            id: "social_close_chart",
                            type: 'line'
                        },
                        dataLabels: {
                            enabled: false
                        },
                        xaxis: {
                            type:'datetime',
                            categories: xdata,
                            tickAmount:10,
                        },
                        yaxis:[{title:{text:'Price ($)'},
                            seriesName:'price_close',
                            decimalsInFloat:0,
                            min:0,
                            max:100,
                            opposite:true},
                            {title:{text:'Mentions'},
                            seriesName:'mentions',
                            decimalsInFloat:0,
                            min:0,
                            max:1000}],
                        noData: {
                            text: 'Search a ticker to load Data...'
                        },
                        legend: {
                            show: false
                        },
                    },
                    series: [],
                }
            }
        }
        else{
            xdata = data.publish_date;
            let max_y2 = Math.max(...data.total);
            max_y2 = Math.round(Math.ceil(max_y2/1000)*1000);
            let totals = [...data.total];
            for (let [key,value] of Object.entries(markerStatuses)){
                if(key !=='Total'){
                    if(value==='active'){
                        for(var i=0; i<totals.length; i++){
                            totals[i] -= data[key.toLowerCase()][i]
                        }
                        series_.push({name:key.toLowerCase(),
                                    data:data[key.toLowerCase()],
                                    type:'column'})
                        widths.push(2)
                        colours.push(COLOURS[key.toLowerCase()])
                        if(yaxis_.length < 1){
                            yaxis_.push({
                                title:{text:'Mentions'}, 
                                seriesName:'mentions',
                                min:0,
                                max:max_y2,
                                decimalsInFloat:0,
                                logarithmic:false
                            })
                        }
                        else{
                            yaxis_.push({
                                title:{text:'Mentions'}, 
                                seriesName:'mentions',
                                show:false,
                                min:0,
                                max:max_y2,
                                logarithmic:false
                            })
                        }
                    }
                }
                else{
                    if(markerStatuses['Total'] === 'active' & totals.reduce((partialSum, a) => partialSum + a, 0) !==0){
                        series_.push({name:key.toLowerCase(),
                            data:totals,
                            type:'column'});
                        widths.push(2);
                        colours.push(COLOURS[key.toLowerCase()]);
                        yaxis_.push({
                            title:{text:'Mentions'}, 
                            seriesName:'mentions',
                            show:false,
                            min:0,
                            max:max_y2,
                            logarithmic:false
                        });
                    }
                }
            }
            widths.push(2);
            yaxis_.push({title:{text:'Price ($)'},
                        seriesName:'price_close',
                        decimalsInFloat:0,
                        opposite:true,
                        min:Math.min(...data.close)*0.9,
                        max:Math.max(...data.close)*1.05});
            colours.push('#22242B')
            series_.push({name: 'price_close',
                            data: data.close,
                            type: 'line' });
            console.log(series_)
            return {
                chart: {
                    options: {
                        colors: colours,
                        chart: {
                            id: "social_close_chart",
                            type: 'line',
                            stacked:'true'
                        },
                        xaxis: {
                            type:'datetime',
                            categories: xdata,
                            tickAmount:10,
                        },
                        yaxis: yaxis_,
                        dataLabels: {
                            enabled: false
                        },
                        stroke: {
                            width: widths
                        },
                        legend: {
                            show: false
                        },
                    },
                    series: series_,
                }
            }
    }
  }
