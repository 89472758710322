import React from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';

import Header from "../components/Header/Header";
import Search from '../components/Search/Search';
import StatsTable from '../components/Stats/Stats';
import ApexChart from '../components/Chart/Chart';
import Footer from "../components/Footer/Footer";

import symbolsData from './symbols.json';
import responseData from './response.json';
import parseData from '../functions/parseData'
import parseStats from '../functions/parseStats'

let symbols_ = JSON.parse(JSON.stringify(symbolsData));


class App extends React.Component {
  constructor(props) {
    super(props);
    let initialData = {};
    let markerStatuses_ = {News:'active',
                           Twitter:'active',
                           Reddit:'active',
                           Other:'active',
                           Total:'active'
                          };
    let chart_ = parseData(initialData, markerStatuses_);
    this.state = {data:initialData,
                  value:'',
                  visible:'hidden',
                  status:'empty',
                  symbols:symbols_,
                  assetType: 'equities',
                  options:symbols_,
                  chart:{options:chart_.chart.options,
                        series:chart_.chart.series},
                  frequency:'daily',
                  chartLength:'3months',
                  stats:{news:null,
                        twitter:null,
                        reddit:null,
                        other:null,
                        total:null
                        },
                  markerStatuses:markerStatuses_
    };
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.clickSuggestions = this.clickSuggestions.bind(this);
    this.hideSuggestions = this.hideSuggestions.bind(this);
    this.showSuggestions = this.showSuggestions.bind(this);
    this.handleAssetType = this.handleAssetType.bind(this);
    this.handleFrequency = this.handleFrequency.bind(this);
    this.handleChartLength = this.handleChartLength.bind(this);
    this.handleChartToggle = this.handleChartToggle.bind(this);
  };

  handleSearch(event) {
    // const api = 'https://flight-deck-api.dev-tools.co.uk';
    // const data = { "ticker" : this.state.value.toUpperCase() };
    // axios
    //   .post(api, data)
    //   .then((response) => {
    //       this.setState({data:response.data}, ()=> console.log('data fetched successfully'))
    //       this.setState(this.parseData(response.data.data), ()=> console.log('data parsed successfully'));
    //     })
    //   .catch((error) => {
    //       console.log(error);
    //   });
    this.setState({data:responseData});
    this.setState(parseData(responseData.data, this.state.markerStatuses), 
                  ()=> {this.setState({stats:parseStats(this.state.data, this.state.frequency)},
                        () => {
                          console.log('data parsed successfully');
                        })
                      });
    this.setState({status:((this.state.data === {}) ?  'empty': 'show') })  
  }
  
  handleChange(event){
    if(event.target.value === ''){
        // this.state.value = event.target.value;
        this.setState({options:this.state.symbols,
                       value:event.target.value,
                       visible:'hidden'});
    }
    if(event.target.value.length>this.state.value.length){
        const options_ = [];
        this.state.options.forEach(row => {
            if(row.symbol.toLowerCase().includes(event.target.value.toLowerCase())){
                options_.push(row)
            }
        });
        this.setState({options:options_,
                       value:event.target.value,
                       visible:'show'},() => {});
    };
    if(event.target.value.length<=this.state.value.length){
        const options_ = [];
        this.state.symbols.forEach(row => {
            if(row.symbol.toLowerCase().includes(event.target.value.toLowerCase())){
                options_.push(row)
            }
        });
        this.setState({options:options_,
                       value:event.target.value,
                       visible:'show'},() => {});
    
    };
  }

  clickSuggestions(event){
    this.setState({value:event.target.value})
  }

  hideSuggestions(event) {
      this.setState({visible:'hidden'},() => {console.log('hide suggestions')});
  }

  showSuggestions(event) {
      this.setState({visible:'show'},() => {console.log('show suggestions')});
  }

  handleFrequency(event) {
    this.setState({frequency:event.target.value}, () => {
        this.setState({stats:parseStats(this.state.data, this.state.frequency)}, 
                    () => {console.log('stats updated')});
    });
  }

  handleAssetType(event) {
    this.setState({assetType:event.target.value}, () => {
        console.log(this.state.assetType);
    });
  }

  handleChartLength(event){
    this.setState({chartLength:event.target.value}, () => console.log('length modified'))
  }

  handleChartToggle(event){
    let marker = event.target.getAttribute('value');
    let statuses = this.state.markerStatuses;
    statuses[marker] = (statuses[marker] === 'active' ? 'inactive' : 'active')
    this.setState({markerStatuses:statuses}, () => {this.setState(parseData(this.state.data.data, this.state.markerStatuses))});
  }

  render() {
    return (
      <div >
        <Header style={{'backgroundColor':'#C4C4C4'}}/>
        <div className='search-chart-container'>
          <Search visible={this.state.visible}
                  value={this.state.value}
                  options={this.state.options}
                  handleClick={this.handleSearch}
                  handleChange={this.handleChange}
                  clickSuggestions={this.clickSuggestions}
                  hideSuggestions={this.hideSuggestions}
                  showSuggestions={this.showSuggestions}
                  handleFrequency={this.handleFrequency}
                  handleAssetType={this.handleAssetType}
                  />
          <StatsTable symbol={(Object.keys(this.state.data).length === 0 ? '-' : this.state.data.meta.ticker)}
                      companyName={(Object.keys(this.state.data).length === 0 ? '-' : this.state.data.meta.company_name)}
                      data={this.state.stats}/>
          <ApexChart symbol={(Object.keys(this.state.data).length === 0 ? '-' : this.state.data.meta.ticker)}
                     companyName={(Object.keys(this.state.data).length === 0 ? '-' : this.state.data.meta.company_name)}
                     chartLength={this.state.chartLength}
                     onChange={this.handleChartLength}
                     onClick={this.handleChartToggle}
                     markerStatuses={this.state.markerStatuses}
                     status={this.state.status}
                     data={this.state.data}
                     chartSeries={this.state.chart.series}
                     chartOptions={this.state.chart.options} />
        </div>
        <Footer/>
      </div>
    );
  }
}
export default App;