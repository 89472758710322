import React from 'react';
import "./Footer.css"

import { faCopyright } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Footer extends React.Component {
    render () {
        let currentYear  = new Date().getFullYear();
        return (
            <div className="footer">
                Copyright <FontAwesomeIcon icon={faCopyright} /> {currentYear}
            </div>
        )
    }
};

export default Footer;