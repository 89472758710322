import React from 'react';
// import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
// import NavDropdown from 'react-bootstrap/NavDropdown'
import Container from 'react-bootstrap/Container';


import "./Header.css"

class Header extends React.Component {


    render () {
      return (
        <Navbar className='nav-colour' expand="lg" variant="light">
          <Container className="headerContainer">
            <Navbar.Brand href="https://www.flightdeck.com"><img src='flight-deck-logo-white.png' alt='flight-deck-logo' style={{'width':'117px', 'marginLeft':'40px'}}/></Navbar.Brand>
            <img src='meltwater-logo.png' alt='meltwater-logo' style={{'width':'147px', 'marginRight':'40px'}}/>
          </Container>
        </Navbar>
      )
    }
};

export default Header;